export const AddUserToRPKey = (key: string, user: string = '') => `resource-planner-${user}-${key}`;

export const GetRPProjectViewTableStateKey = (viewId: string = '', user?: string) =>
  AddUserToRPKey(`${viewId}-project-view-row-expand-state`, user);

export const GetRPEmployeeViewTableStateKey = (viewId: string = '', user?: string) =>
  AddUserToRPKey(`${viewId}-employee-view-row-expand-state`, user);

export const RPViewOptionsStateKey = 'resource-planner-view-options';

export const GetRPSelectedFilterListKey = (viewId: string = '', user?: string) =>
  AddUserToRPKey(`${viewId}-selected-filter-list`, user);

export const RPInvalidateOldQueryKey = 'resource-planner-invalidate-old-query';

export const GetRPSavedViewsKey = (user?: string) => AddUserToRPKey('saved-views', user);

export const GetRPReturningUserKey = (user?: string) => AddUserToRPKey('returning-user', user);

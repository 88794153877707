import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import {
  IResourcePlannerPeriodColumn,
  IResourcePlannerPeriodValueString,
  IResourcePlannerTotalResult,
} from 'src/apis/types/resourcePlannerAPI';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { useIsSidePanelOpen } from 'src/components/layout/SidePanel';
import { Flex, Shimmer, TCellIndentation, Table } from 'src/components/ui-components';
import { stringToPascal } from 'src/utils/string';
import { RPEmployeeViewDisableExpandAllCount } from '../../constants';
import { getExpandedTableRowIds } from '../../helper/expandedRow';
import { getNotPlannedFontColor } from '../../helper/getNotPlannedFontColor';
import { GetRPEmployeeViewTableStateKey } from '../../localStorageKeys';
import { RPRow, RTColumn, RTRow } from '../../types/resourcePlanner';
import MemoizedRenderBodyCellColumn from '../MemoizedRenderBodyCellColumn';
import MemoizedRenderBodyCellPeriodColumn from '../MemoizedRenderBodyCellPeriodColumn/MemoizedRenderBodyCellPeriodColumn';
import MemoizedTableColGroupColumn from '../MemoizedTableColGroupColumn/MemoizedTableColGroupColumn';
import NameColumnRenderer from '../NameColumnRenderer';
import ToggleRowsButton from '../ToggleRowsButton';
import UnplannedCell from '../UnplannedCell';
import MemoizedFooterRow from './MemoizedFooterRow';
import RenderBodyFirstColumn from './RenderBodyFirstColumn';

interface IResourceTableGroupedByEmployeeTable {
  columns: ColumnDef<RPRow>[];
  data: RPRow[];
  handleOnToggleRow: (row: RTRow) => void;
  onCellValueChange: (row: RTRow, column: RTColumn, value: string) => void;
  periods: IResourcePlannerPeriodColumn[];
  selectedViewOptions: {
    [key: string]: string;
  };
  setExpandedRows: Function;
  t: TFunction<'resourcePlanner', undefined>;
  totalRow: Partial<IResourcePlannerTotalResult>;
  unitType: string;
  shortNameWithoutUrl?: string;
  viewId?: string;
}

const ResourceTableGroupedByEmployeeTable = ({
  columns,
  data,
  handleOnToggleRow,
  onCellValueChange,
  periods,
  selectedViewOptions,
  setExpandedRows,
  shortNameWithoutUrl,
  t,
  totalRow,
  unitType,
  viewId,
}: IResourceTableGroupedByEmployeeTable) => {
  const siteLocale = useGetLocale();
  const sidePanelIsOpen = useIsSidePanelOpen();

  const [expanded, setExpanded] = useState<ExpandedState>(() =>
    getExpandedTableRowIds(GetRPEmployeeViewTableStateKey(viewId, shortNameWithoutUrl)),
  );

  const getShouldDisableExpandAll = () => {
    if (data && data.length >= RPEmployeeViewDisableExpandAllCount) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setExpanded(
      getExpandedTableRowIds(GetRPEmployeeViewTableStateKey(viewId, shortNameWithoutUrl)),
    );
  }, [data, shortNameWithoutUrl, viewId]);

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    meta: {
      updateData: onCellValueChange,
    },
    getSubRows: (row) => (row?.children && row?.children?.length > 0 ? row.children : []),
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: (row: RTRow) => !!row.original.canExpand,
  });

  return (
    <Table
      sidePanelIsOpen={sidePanelIsOpen}
      stickyFirstColumn
      stickyLastColumn
      stickyFooter
      compact
      tableHover
      data-automation-id="ResourceViewTable"
    >
      <Table.ColGroup>
        {table
          .getHeaderGroups()
          .map((headerGroup) =>
            headerGroup.headers.map((header) => (
              <MemoizedTableColGroupColumn key={`colGroup_col_${header.column.columnDef.id}`} />
            )),
          )}
      </Table.ColGroup>

      <Table.Head>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Row key={`headerGroup_row_${headerGroup.id}`}>
            {headerGroup.headers.map((header) => (
              <Table.Header
                key={`header_${header.column.columnDef.id}`}
                alignment={header.column.columnDef.meta?.alignment}
                id={`tableHeaderCell${stringToPascal(header.column.id)}`}
              >
                {header.column.columnDef.id === 'expandCollapseAllRows' ? (
                  <Flex key={header.id}>
                    <ToggleRowsButton
                      rows={table.getRowModel().flatRows}
                      setExpandedRows={setExpandedRows}
                      viewStorageKey={GetRPEmployeeViewTableStateKey(viewId, shortNameWithoutUrl)}
                      handleOnToggleRow={handleOnToggleRow}
                      disableExpandAll={getShouldDisableExpandAll()}
                      isGroupedByProject={false}
                    />
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Flex>
                ) : (
                  <span title={header.column.columnDef.meta?.title}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </span>
                )}
              </Table.Header>
            ))}
          </Table.Row>
        ))}
      </Table.Head>

      <MemoizedFooterRow
        totalColumnTranslatedName={t('Total')}
        unitType={unitType}
        siteLocale={siteLocale}
        totalBudget={totalRow?.model?.properties?.row?.totalBudget}
        totalBooked={totalRow?.model?.properties?.row?.totalBooked}
        totalActualWorkload={totalRow?.model?.properties?.row?.totalActualWorkload}
        totalNotPlanned={totalRow?.model?.properties?.row?.totalNotPlanned}
        periods={periods}
        totalRow={totalRow}
        totalRowDisplayValue={totalRow?.model?.properties?.row?.total?.displayValue}
      />

      <Table.Body>
        {table.getRowModel().rows.map((row) => (
          <Table.Row key={`body_row_${row.id}`}>
            {row.getVisibleCells().map((cell) => {
              if (row.original.isLoading) {
                return (
                  <Table.Cell
                    alignment={cell.column.columnDef.meta?.alignment}
                    type={cell.column.columnDef.meta?.type}
                    key={`row${row.id}col${cell.column.columnDef.id}`}
                    indent={
                      cell.column.columnDef.id === 'name'
                        ? (row.depth as TCellIndentation)
                        : (0 as TCellIndentation)
                    }
                    valign="bottom"
                  >
                    <Shimmer height="quarter" />
                  </Table.Cell>
                );
              }

              if (cell.column.columnDef.id === 'expandCollapseAllRows') {
                return (
                  <RenderBodyFirstColumn
                    depth={row.depth as TCellIndentation}
                    alignment="left"
                    key={`row${row.id}col${cell.column.columnDef.id}`}
                  >
                    <NameColumnRenderer
                      row={row}
                      value={cell.getValue() as string}
                      handleOnToggleRow={handleOnToggleRow}
                      getRowModel={table.getRowModel}
                    />
                  </RenderBodyFirstColumn>
                );
              }

              if (cell.column.columnDef.id?.startsWith('periodCol_')) {
                const periodColumnIdSubString = cell.column.columnDef.id.substring(
                  cell.column.columnDef.id.indexOf('_') + 1,
                  cell.column.columnDef.id.length,
                );

                const periodCellData: IResourcePlannerPeriodValueString = cell.column.columnDef.meta
                  ?.editable
                  ? row.original.values[periodColumnIdSubString]
                  : (cell.getValue() as IResourcePlannerPeriodValueString);

                const isCellEditable =
                  (row.original.editable &&
                    cell.column.columnDef?.meta?.editable &&
                    periodCellData?.editable) ||
                  false;

                if (
                  !isCellEditable &&
                  row.original.hierarchyType !== 'resource' &&
                  (selectedViewOptions['reporting-types'] === 'availability' ||
                    unitType === 'percentages') &&
                  periodCellData.displayValue.toString() === '0'
                ) {
                  return (
                    <Table.Cell
                      key={`row_cell_${cell.id}_${cell.column.columnDef.id}`}
                      dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                    />
                  );
                }

                return (
                  <MemoizedRenderBodyCellPeriodColumn
                    alignment={cell.column.columnDef.meta?.alignment}
                    editable={cell.column.columnDef.meta?.editable}
                    type={cell.column.columnDef.meta?.type}
                    heatmapCode={periodCellData?.heatmapCode}
                    key={`row${row.id}col${cell.column.columnDef.id}`}
                    row={row}
                    column={cell.column}
                    handleOnCellValueChanged={table.options.meta?.updateData}
                    isCellEditable={isCellEditable}
                    unitType={unitType}
                    reportingType={selectedViewOptions['reporting-types']}
                    dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                  />
                );
              }

              if (cell.column.columnDef.id === 'notPlanned') {
                return (
                  <MemoizedRenderBodyCellColumn
                    alignment={cell.column.columnDef.meta?.alignment}
                    editable={cell.column.columnDef.meta?.editable}
                    type={cell.column.columnDef.meta?.type}
                    key={`row${row.id}col${cell.column.columnDef.id}`}
                    cellValue={cell.getValue() as string}
                    fontColor={getNotPlannedFontColor(cell.getValue<number>())}
                    startsAt={cell.row.original.startsAt}
                    endsAt={cell.row.original.endsAt}
                  >
                    <UnplannedCell
                      hierarchyType={cell.row.original.hierarchyType}
                      resourceId={cell.row.original.resourceId}
                      workItemId={
                        cell.row.original.type?.toLowerCase() === 'employee'
                          ? ''
                          : cell.row.original.id
                      }
                      resourceName={
                        cell.row.original.hierarchyType === 'resource'
                          ? cell.row.original.name
                          : cell.row.original?.rootParentName ?? ''
                      }
                      workItemName={
                        cell.row.original.hierarchyType === 'resource' ? '' : cell.row.original.name
                      }
                      isLowestLevel={
                        cell.row.original.children === undefined ||
                        cell.row.original.children?.length < 1
                      }
                      startsAt={cell.row.original.startsAt}
                      endsAt={cell.row.original.endsAt}
                      negativeUnplannedHours={cell.row.original.negativeUnplannedHours}
                      type={cell.row.original.type}
                      hasChildWithEndDateOnTodayOrAfter={
                        cell.row.original.hasChildWithEndDateOnTodayOrAfter
                      }
                      workItemSourceReferenceId={cell.row.original.workItemSourceReferenceId}
                      workItemType={cell.row.original.workItemType}
                      resourceSourceReferenceId={cell.row.original.resourceSourceReferenceId}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </UnplannedCell>
                  </MemoizedRenderBodyCellColumn>
                );
              }

              return (
                <MemoizedRenderBodyCellColumn
                  alignment={cell.column.columnDef.meta?.alignment}
                  editable={cell.column.columnDef.meta?.editable}
                  type={cell.column.columnDef.meta?.type}
                  key={`row${row.id}col${cell.column.columnDef.id}`}
                  cellValue={cell.getValue<string | undefined>()}
                  dividerBorderLeft={cell.column.columnDef.meta?.dividerBorderLeft}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </MemoizedRenderBodyCellColumn>
              );
            })}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default ResourceTableGroupedByEmployeeTable;
